body {
  font-family: sans-serif;
  display: flex;
  margin: 0;
}

.container {
  display: flex;
  width: 100%;
}

.point-columns,
.point-columns-up {
  display: flex;
  width: 100%;
  height: 100%;
}

.dice-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dice {
  display: flex;
}

.die,
.die-disabled {
  width: 1.5em;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;
  border-radius: 10px;
}

.die-disabled {
  opacity: 50%;
}

.point-columns-up {
  padding-bottom: 1%;
}

.point-columns-up > div {
  justify-content: flex-end;
}

.point-columns-up .point-l2 {
  margin: 0 0 5% 0;
}

.point-columns-up .point-l3 {
  margin: 0 0 10% 0;
}

.point-columns-up .point-l4 {
  margin: 0 0 15% 0;
}

.point-columns-up .point-l5 {
  margin: 0 0 20% 0;
}

.point {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.point-up {
  justify-content: flex-end;
}

.point-l2,
.point-l3,
.point-l4,
.point-l5 {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 16.65%;
  align-items: center;
}

.point-l2 {
  margin-top: 5%;
}

.point-l3 {
  margin-top: 10%;
}

.point-l4 {
  margin-top: 15%;
}

.point-l5 {
  margin-top: 20%;
}

.board-left,
.board-right {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: url(MOMA.svg);
}

/* .checker {
  border: 1px solid darkcyan;
  box-shadow: 2px 2px darkcyan;
  position: relative;
  border-radius: 50%;
  width: 75%;
  height: auto;
  padding-top: 75%;
} */

.bar {
  background-color: yellow;
  display: flex;
  width: 15%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.bar .checker {
  width: 83.5%; /*TODO: calc()?*/
}

.checker {
  pointer-events: none;
  border: 1px solid darkcyan;
  box-shadow: 2px 2px darkcyan;
  border-radius: 50%;
  width: 75%;
  aspect-ratio: 1/1;
}

.bear-off-tray {
  display: flex;
  flex-direction: column;
  background-color: red;
  padding: 0.5%;
  width: 15%;
}

.bear-off-tray-top,
.bear-off-tray-bottom {
  display: flex;
  flex-direction: column;
  height: 50%;
}

.bear-off-tray-bottom {
  justify-content: flex-end;
}

.bear-off-checker-player-one,
.bear-off-checker-player-two {
  margin-bottom: 1px;
  display: flex;
  height: 3.5%;
  width: 100%;
  border-radius: 2px;
}
.bear-off-checker-player-one {
  background-image: linear-gradient(to right, darkcyan, white, white, white, darkcyan);
}
.bear-off-checker-player-two {
  background-image: linear-gradient(to right, black, black, darkcyan, black, black);
}
