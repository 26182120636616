body {
  display: flex;
  height: 100vh;
  margin: 0;
  font-family: sans-serif;
}

#root {
  width: 100%;
  display: inherit;
}
